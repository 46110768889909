import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {AgGridReact, AgGridReactProps} from '@ag-grid-community/react';
import {
  GridApi,
  ColDef,
  GetRowIdParams,
  GridReadyEvent,
} from '@ag-grid-community/core';
import {TableStyle} from './TableStyle';

interface TableProps<T> extends AgGridReactProps<T> {
  columnDefs: ColDef<T>[];
  onGridReady?: (params: GridReadyEvent) => void;
  isLoading?: boolean;
}

const Table = <T extends {id: string}>({
  columnDefs,
  onGridReady: initOnGridReady,
  isLoading,
  ...props
}: TableProps<T>) => {
  const [gridApi, setGridApi] = useState<GridApi | undefined>();

  const defaultColDef = useMemo<ColDef<T>>(
    () => ({
      resizable: true,
      sortable: false,
      filter: false,
      filterParams: {
        defaultOption: 'equals',
        buttons: ['apply', 'reset'],
        closeOnApply: true,
        maxNumConditions: 1,
      },
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
      },
    }),
    []
  );

  const rowStyle = useMemo(
    () => ({
      cursor: 'pointer',
    }),
    []
  );

  const getRowId = useCallback(
    (params: GetRowIdParams<T>) => params.data.id,
    []
  );

  const onGridReady = useCallback((params: GridReadyEvent) => {
    setGridApi(params.api);
    initOnGridReady?.(params);
  }, []);

  useEffect(() => {
    gridApi?.setGridOption('loading', isLoading || false);
  }, [gridApi, isLoading]);

  return (
    <TableStyle>
      <div className="ag-theme-quartz" style={{height: '100%', width: '100%'}}>
        <AgGridReact<T>
          onGridReady={onGridReady}
          popupParent={document.body}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowStyle={rowStyle}
          getRowId={getRowId}
          pagination
          cacheBlockSize={100}
          cacheOverflowSize={2}
          maxConcurrentDatasourceRequests={1}
          infiniteInitialRowCount={1}
          maxBlocksInCache={2}
          {...props}
        />
      </div>
    </TableStyle>
  );
};

export default Table;
