import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material';
import React, {FC} from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

const SearchBar: FC<
  Omit<TextFieldProps, 'onChange'> & {onChange: (text: string) => void}
> = ({value, onChange, ...props}) => (
  <TextField
    {...props}
    value={value}
    onChange={e => onChange(e.currentTarget.value)}
    InputProps={{
      style: {
        fontSize: 12,
        width: '100%',
        maxWidth: 310,
        color: 'primary.main',
      },
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon fontSize="small" />
        </InputAdornment>
      ),
      endAdornment: value ? (
        <InputAdornment position="end">
          <IconButton size="small" onClick={() => onChange('')}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </InputAdornment>
      ) : undefined,
    }}
  />
);

export default SearchBar;
