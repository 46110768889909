import {Outlet} from 'react-router-dom';
import React, {lazy} from 'react';
import IfHasPermissions from '@local/frontend/hocs/IfHasPermissions';
import {MultiCardPaymentsScope} from '@local/backend/@types/scopes/MultiCardPaymentsScopes';
import MultiCardValidationWrapper from '@local/frontend/providers/MultiCardValidationWrapper';

const ListMultiCardPayments = lazy(
  () => import('./ListMultiCardPayments/ListMultiCardPayments')
);

const ManageMultiCardPaymentPage = lazy(
  () => import('./manage/ManageMultiCardPaymentPage')
);

const CreateMultiCardPaymentPage = lazy(
  () => import('./create/CreateMultiCardPaymentPage')
);

const ConfirmMultiCardPaymentPage = lazy(
  () => import('./create/ConfirmMultiCardPaymentPage')
);

export const multiCardPaymentRoutes = {
  path: 'multi-cards',
  element: <Outlet />,
  children: [
    {
      index: true,
      element: (
        <IfHasPermissions
          thenRender={() => <ListMultiCardPayments />}
          permissions={[MultiCardPaymentsScope.READ]}
        />
      ),
    },
    {
      path: ':multiCardPaymentId',
      element: (
        <MultiCardValidationWrapper>
          <Outlet />
        </MultiCardValidationWrapper>
      ),
      children: [
        {
          index: true,
          element: (
            <IfHasPermissions
              thenRender={() => <ManageMultiCardPaymentPage />}
              permissions={[MultiCardPaymentsScope.READ]}
            />
          ),
        },
        {
          path: 'confirm',
          element: (
            <IfHasPermissions
              thenRender={() => <ConfirmMultiCardPaymentPage />}
              permissions={[MultiCardPaymentsScope.CREATE]}
            />
          ),
        },
      ],
    },
    {
      path: 'create',
      element: (
        <IfHasPermissions
          thenRender={() => <CreateMultiCardPaymentPage />}
          permissions={[MultiCardPaymentsScope.CREATE]}
        />
      ),
    },
  ],
};
