import {InfoOutlined} from '@mui/icons-material';
import {Card, IconButton, Typography, Tooltip, Skeleton} from '@mui/material';
import React, {FC} from 'react';

export interface WidgetProps {
  label: string;
  value: string | number;
  info?: string | React.ReactNode;
  comparison?: number;
  isLoading?: boolean;
}

const Widget: FC<WidgetProps> = ({
  label,
  value,
  comparison,
  info,
  isLoading,
}) => (
  <Card
    sx={{
      minWidth: 200,
      py: 4,
      px: 2,
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
    }}
  >
    {info && (
      <Tooltip title={info} arrow>
        <IconButton
          sx={{position: 'absolute', right: 10, top: 10}}
          size="small"
        >
          <InfoOutlined fontSize="small" />
        </IconButton>
      </Tooltip>
    )}
    {isLoading ? (
      <Skeleton variant="text" width={100}>
        <Typography variant="h4" textAlign="center">
          {value}
        </Typography>
      </Skeleton>
    ) : (
      <Typography variant="h4" textAlign="center">
        {value}
      </Typography>
    )}

    <Typography textAlign="center">{label}</Typography>
    {comparison !== undefined && (
      <Typography textAlign="center">
        {(comparison * 100).toFixed(2)}
      </Typography>
    )}
  </Card>
);

export default Widget;
