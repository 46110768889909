import {styled} from '@mui/material';
import '@ag-grid-community/styles/ag-grid.css'; // Core CSS
import '@ag-grid-community/styles/ag-theme-quartz.css'; // Theme

export const TableStyle = styled('div')`
  width: 100%;
  height: 100%;

  @media screen and (max-width: 720px) {
    div.ag-theme-quartz,
    div.ag-theme-quartz-dark {
      --ag-font-size: 12px;
      --ag-grid-size: 6px;
    }
  }

  .ag-theme-quartz {
    --ag-background-color: none;
    --ag-font-family: Poppins, sans-serif;
  }

  .ag-theme-quartz .ag-body-viewport,
  .ag-theme-quartz-dark .ag-body-viewport {
    padding-top: 8px;
  }

  .ag-theme-quartz .ag-row-group .ag-icon,
  .ag-theme-quartz-dark .ag-row-group .ag-icon {
    opacity: 0.5;
  }

  .ag-theme-quartz .ag-row,
  .ag-theme-quartz-dark .ag-row {
    border-bottom: none;
  }

  .ag-theme-quartz .ag-row:not(.ag-row-level-1),
  .ag-theme-quartz-dark .ag-row:not(.ag-row-level-1) {
    border-radius: 8px;
    overflow: hidden;
  }

  /* Required to transition background color for row hover */
  .ag-theme-quartz .ag-row:not(.ag-row-level-1)::before,
  .ag-theme-quartz-dark .ag-row:not(.ag-row-level-1)::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
  }

  .ag-theme-quartz .ag-cell-expandable,
  .ag-theme-quartz-dark .ag-cell-expandable {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ag-theme-quartz .ag-cell,
  .ag-theme-quartz-dark .ag-cell {
    display: flex;
    align-items: center;
  }

  .ag-theme-quartz .ag-cell:first-child,
  .ag-theme-quartz-dark .ag-cell:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .ag-theme-quartz .ag-cell:last-child,
  .ag-theme-quartz-dark .ag-cell:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .ag-theme-quartz .ag-right-aligned-cell,
  .ag-theme-quartz-dark .ag-right-aligned-cell {
    justify-content: flex-end;
  }

  .ag-theme-quartz-dark .ag-header {
    background-color: ${({theme}) => theme.palette.primary.lighter};
    border-radius: 8px;
    border: none;
  }

  .ag-theme-quartz .ag-header {
    background-color: ${({theme}) => theme.palette.primary.lighter};
    color: #39485d;
    border-radius: 8px;
    border: 1px solid #eff0f1;
  }

  .ag-theme-quartz .ag-header-cell-text,
  .ag-theme-quart-dark .ag-header-cell-text {
    color: #1f383c;
    display: flex;
    align-items: center;
  }

  .ag-theme-quartz-dark .ag-header-cell-text {
    color: #c9d0da;
  }

  .ag-theme-quartz .ag-root-wrapper,
  .ag-theme-quartz-dark .ag-root-wrapper {
    border: none;
  }

  .ag-theme-quartz .ag-full-width-container .ag-row,
  .ag-theme-quartz-dark .ag-full-width-container .ag-row,
  .ag-theme-quartz .ag-full-width-container .ag-details-row,
  .ag-theme-quartz-dark .ag-full-width-container .ag-details-row {
    background-color: transparent;
  }

  .ag-theme-quartz .ag-details-row,
  .ag-theme-quartz-dark .ag-details-row {
    padding: 16px;
  }

  .ag-theme-quartz .ag-paging-panel {
    height: 40px;
  }

  .ag-theme-quartz .ag-paging-panel > span:last-child {
    margin-right: 0;
  }

  .ag-center-cols-viewport {
    min-height: unset !important;
  }

  .grid {
    --ag-header-background-color: transparent !important;

    height: calc(
      100vh - var(--layout-grid-header-height) - 62px - var(
          --layout-grid-margin
        )
    );
    margin: 0 var(--layout-grid-margin) var(--layout-grid-margin);
  }
`;
