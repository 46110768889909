import {Outlet} from 'react-router-dom';
import React, {lazy} from 'react';
import IfHasPermissions from '@local/frontend/hocs/IfHasPermissions';
import {CustomersScope} from '@local/backend/@types/scopes/CustomersScopes';

const ListCustomers = lazy(() => import('./ListCustomers/ListCustomers'));

const ManageCustomerPage = lazy(() => import('./manage/ManageCustomerPage'));

export const customersRoutes = {
  path: 'customers',
  element: <Outlet />,
  children: [
    {
      index: true,
      element: (
        <IfHasPermissions
          thenRender={() => <ListCustomers />}
          permissions={[CustomersScope.READ]}
        />
      ),
    },
    {
      path: ':customerId',
      element: (
        <IfHasPermissions
          thenRender={() => <ManageCustomerPage />}
          permissions={[CustomersScope.READ]}
        />
      ),
    },
  ],
};
