import React, {ReactNode} from 'react';
import {Stack, Button, Typography, CircularProgress} from '@mui/material';
import {useMerchant} from '@local/frontend/libs/trpc/trpc';
import {GridApi} from '@ag-grid-community/core';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import SearchBar from './SearchBar';
import DateTimeRangePicker from '../atoms/input/DateTimeRangePicker';

interface TableHeaderProps {
  resultsFound: number;
  isFetching: boolean;
  searchValue: string;
  rangeStartDate?: Date;
  rangeEndDate?: Date;
  onSearchChange: (value: string) => void;
  onRangeChange: (startDate: Date | null, endDate: Date | null) => void;
  gridApi: GridApi | undefined;
  searchPlaceholder: string;
  actionButtons?: ReactNode[];
  exportFilename: string;
}

const TableHeader: React.FC<TableHeaderProps> = ({
  resultsFound,
  isFetching,
  searchValue,
  rangeStartDate,
  rangeEndDate,
  onSearchChange,
  onRangeChange,
  gridApi,
  searchPlaceholder,
  actionButtons,
  exportFilename,
}) => {
  const {data: merchant} = useMerchant();
  return (
    <Stack
      direction={{xs: 'column', md: 'row'}}
      alignItems="center"
      justifyContent={{xs: 'flex-start', md: 'space-between'}}
      spacing={1}
      useFlexGap
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        justifyContent={{xs: 'space-between', md: 'flex-start'}}
        width="100%"
      >
        <SearchBar
          size="small"
          variant="outlined"
          color="primary"
          placeholder={searchPlaceholder}
          onChange={onSearchChange}
          value={searchValue}
        />
        {isFetching ? (
          <CircularProgress variant="indeterminate" size={20} />
        ) : (
          <Typography variant="caption">{resultsFound} results</Typography>
        )}
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        width="100%"
        justifyContent={{xs: 'flex-start', md: 'flex-end'}}
      >
        {actionButtons}
        <Button
          disabled={!gridApi}
          color="inherit"
          variant="outlined"
          size="small"
          onClick={() => {
            gridApi?.exportDataAsCsv({
              fileName: `${exportFilename}.csv`,
              exportedRows: 'all',
              allColumns: true,
            });
          }}
          startIcon={<DownloadForOfflineIcon />}
        >
          Export
        </Button>

        <DateTimeRangePicker
          disabled={!gridApi}
          minDate={
            merchant?.createdAt ? new Date(merchant.createdAt) : undefined
          }
          maxDate={new Date()}
          startDate={rangeStartDate}
          onRangeChange={onRangeChange}
          endDate={rangeEndDate}
        />
      </Stack>
    </Stack>
  );
};

export default TableHeader;
