import {Stack} from '@mui/material';
import React from 'react';
import {
  useLatestExchangeRates,
  useMerchant,
} from '@local/frontend/libs/trpc/trpc';
import {GlobalMoneyCalculator, MoneyUtils} from '@handsin/money';
import {Navigate} from 'react-router-dom';
import Loading from '@local/frontend/components/atoms/Loading';
import dayjs from 'dayjs';
import type {PaymentItem} from '@local/backend/db/payments';
import Widget from '../../../../components/graphs/Widget';

export interface PaymentWidgetsProps {
  paymentData: PaymentItem[];
  isLoading?: boolean;
}

const PaymentWidgets: React.FC<PaymentWidgetsProps> = ({
  paymentData,
  isLoading: isPaymentsLoading,
}) => {
  const {data: merchant} = useMerchant();

  const {data: fxRates, isLoading: isFxRatesLoading} = useLatestExchangeRates();

  const isLoading = isPaymentsLoading || isFxRatesLoading;

  if (!merchant) {
    return <Navigate to="/403" />;
  }

  if (!fxRates) {
    return <Loading />;
  }

  const totalCaptured = (paymentData ?? [])
    .reduce(
      (acc, payment) =>
        payment.capturedMoney ? acc.add(payment.capturedMoney) : acc,
      new GlobalMoneyCalculator(
        {amount: 0, currency: merchant.defaultCurrency},
        fxRates
      )
    )
    .calculate();

  const totalRefunded = (paymentData ?? [])
    .reduce(
      (acc, payment) =>
        payment.refundedMoney ? acc.add(payment.refundedMoney) : acc,
      new GlobalMoneyCalculator(
        {amount: 0, currency: merchant.defaultCurrency},
        fxRates
      )
    )
    .calculate();

  const netRevenue = new GlobalMoneyCalculator(totalCaptured, fxRates)
    .subtract(totalRefunded)
    .calculate();

  const completedPayments =
    paymentData?.filter(payment => payment.status === 'COMPLETED') ?? [];

  return (
    <Stack
      direction="row"
      spacing={2}
      height={200}
      p={1}
      sx={{overflowX: 'auto', overflowY: 'visible'}}
    >
      <Widget
        label="Total payments"
        value={paymentData?.length ?? 0}
        isLoading={isLoading}
      />
      <Widget
        label="Total captured"
        value={MoneyUtils.formatMoney(totalCaptured)}
        isLoading={isLoading}
      />
      <Widget
        label="Total refunded"
        value={MoneyUtils.formatMoney(totalRefunded)}
        isLoading={isLoading}
      />
      <Widget
        label="Total revenue"
        value={MoneyUtils.formatMoney(netRevenue)}
        isLoading={isLoading}
      />
      <Widget
        label="Conversion rate"
        value={`${(
          ((completedPayments.length ?? 0) / (paymentData?.length ?? 1)) *
          100
        ).toFixed(2)} %`}
        isLoading={isLoading}
      />
      <Widget
        label="Average completion time"
        value={`${(
          completedPayments
            .filter(p => !p.refundedMoney)
            .reduce((acc, payment) => {
              if (payment.createdAt && payment.updatedAt) {
                return (
                  acc +
                  dayjs(payment.updatedAt).unix() -
                  dayjs(payment.createdAt).unix()
                );
              }
              return acc;
            }, 0) /
          ((completedPayments.length || 1) * 60)
        ).toFixed(2)} mins`}
        isLoading={isLoading}
      />
    </Stack>
  );
};

export default PaymentWidgets;
